import React, { Component } from 'react'
import Image from 'react-bootstrap/Image'
import Jumbotron from 'react-bootstrap/Jumbotron'
import { Container, Row, Col, Button, Card, Accordion, Breadcrumb } from 'react-bootstrap'
import { connect } from 'react-redux'

class FaqPage extends Component {
    state = {}
    render() {
        return (
            <div>
                <Jumbotron className="faq-jumbotron" fluid>
                    <Container fluid>
                        <Row>
                            <Container fluid>
                                {this.props.language == "English" ? <h2 className="">Frequently Asked Questions</h2> :
                                    <h2 className="">常问问题</h2>
                                }
                            </Container>
                        </Row>

                    </Container>
                </Jumbotron>

                <Container>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                        <Breadcrumb.Item active>FAQ</Breadcrumb.Item>
                    </Breadcrumb>

                    {this.props.language == "English" ?
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    What is Acupuncture?
                            </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>Acupuncture is treatment based on Traditional Chinese medicine system where sterile, solid, hair thin needles are inserted into specific points in the body along the meridians that course throughout the body. It stimulates release of endorphins and improves circulation. Endorphins in your body are potent and natural painkillers, they enhance immunity, and reduce effects of stress on your body.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                    Does Acupuncture hurt?
                             </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>Pain is not a desired sensation during an acupuncture treatment. A feeling of heaviness, warmth, or tingling are more common descriptions.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                    What is Acupuncture good for?
                             </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>People may see an acupuncturist for a variety of conditions, from indigestion to shoulder pain. Some of the more commonly treated conditions are:
                                    Psychologically related conditions such as depression, anxiety, stress, and insomnia
                                    Digestive problems such as acid reflux, irritable bowel syndrome, constipation, etc.
                                    Menstrual issues such as PMS, amenorrhea, irregular menstruation, etc.
                                    Overall low energy and fatigue
Pain anywhere in the body including headaches, migraines, sciatica, and injury.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="3">
                                    How many treatments will I need?
                             </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>Number and frequency of treatments is dependant your individual needs. Your personal plan will be discussed at your initial consultation.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="4">
                                    How will I feel after my treatment?
                             </Accordion.Toggle>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>Different people will react differently to acupuncture. Often times people feel relaxed and sleepy. Others will feel energized but we still suggest to take it easy after treatments. If you have pain, the pain may be relieved after the treatment but sometimes it may take a couple hours or longer to feel the effects. In certain conditions, people may feel a little worse before symptoms get better.</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        :
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                    什么是针灸？
                                                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>针灸是一种基于传统中医系统的治疗方法，将无菌，结实，细发的细针沿着整个人体的经线插入人体的特定部位。 它刺激内啡肽释放并改善循环。 体内的内啡肽是有效的天然止痛药，它们可增强免疫力并减少压力对身体的影响。</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                    针灸会痛吗？
                             </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>针灸治疗期间疼痛不是您想要的感觉。 较重的描述是沉重，温暖或刺痛的感觉</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                    针灸有什么好处？
                             </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>从消化不良到肩部疼痛，人们可能会遇到针灸师的各种疾病。 一些较常见的疾病是：与心理有关的疾病，例如抑郁，焦虑，压力和失眠等消化系统疾病，例如反酸，肠易激综合征，便秘等月经问题，例如PMS，闭经，月经不调等。 总体低能量和疲劳身体上任何地方的疼痛，包括头痛，偏头痛，坐骨神经痛和伤害。
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="3">
                                    我需要多少治疗？
                             </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>治疗的次数和频率取决于您的个人需求。 您的个人计划将在您初次咨询时进行讨论。</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="4">
                                    治疗后感觉如何？
                             </Accordion.Toggle>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>不同的人对针灸会有不同的反应。 人们常常会感到放松和困倦。 其他人会感到精力充沛，但我们仍然建议在治疗后放松一下。 如果您感到疼痛，治疗后疼痛可能会缓解，但有时可能需要几个小时或更长时间才能感受到效果。 在某些情况下，人们可能会在症状好转之前感到更糟。</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    }
                </Container>
                <br />
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    console.log(state)
    return {
        language: state.language.currentLanguage
    }
}

export default connect(mapStateToProps)(FaqPage)