import React, { Component } from 'react'
import Jumbotron from 'react-bootstrap/Jumbotron'
import { Container, Row, Col, Breadcrumb, Image } from 'react-bootstrap'
import img1 from '../img/Eddy Pun Portrait.jpg'
import { connect } from 'react-redux'

class AboutPage extends Component {
  state = {}
  render () {
    return (
      <div>
        <Jumbotron className='about-jumbotron' fluid>
          <Container fluid>
            <Row>
              <Container fluid>
                {this.props.language == 'English' ? (
                  <h1 className=''>About Us</h1>
                ) : (
                  <h1 className=''>关于我们</h1>
                )}
              </Container>
            </Row>
          </Container>
        </Jumbotron>

        <Container>
          {this.props.language == 'English' ? (
            <Breadcrumb>
              <Breadcrumb.Item href='/'>Home</Breadcrumb.Item>

              <Breadcrumb.Item active>About Us</Breadcrumb.Item>
            </Breadcrumb>
          ) : (
            <Breadcrumb>
              <Breadcrumb.Item href='/'>首页</Breadcrumb.Item>

              <Breadcrumb.Item active>关于我们</Breadcrumb.Item>
            </Breadcrumb>
          )}
          <br />
          <Row className='info-row'>
            <Col className='biography-text' md='12'>
              <h2></h2>

              {this.props.language == 'English' ? (
                <p className='biography-body'>
                  <br />
                  We are passionate about helping people using Traditional
                  Chinese Medicine (TCM). We believe that our outward appearance
                  comes from our inner health and prevention is better than
                  cure. Several important factors that affect our inner health
                  are nutrition, emotion, quality of sleep and our gut health.
                  Our practitioner held a Master’s degree in Information
                  Technology from University of Western Sydney and Traditional
                  Chinese Medicine from University of Technology, Sydney. The
                  practitioner is a registered Chinese Medicine Acupuncturist/
                  Herbalist in Australia and a member of CMASA and is currently
                  studying a Master Course in TCM in Western Sydney University.
                  He has rich clinical experience in herbal medicine,
                  acupuncture, dietary advice, moxa, cupping, massage, ear
                  acupuncture, and other modalities. His special interests are:
                  <br />
                  <br />
                  <ul>
                    <li>skin diseases (acne, eczema, psoriasis, rosacea)</li>
                    <li>
                      {' '}
                      insomnia & mood disorders (stress, anxiety, depression)
                    </li>
                    <li>
                      {' '}
                      cosmetic acupuncture (tension, pigmentation, uneven skin
                      tone, pimples and acne, dryness and dullness, stimulate
                      Collagen production, drooping)
                    </li>
                    <li>
                      {' '}
                      women’s health (pelvic pain, uterine fibroids, polycystic
                      ovary syndrome, irregular menstruation, dysmenorrhea,
                      infertility)
                    </li>
                    <li>
                      {' '}
                      digestive system diseases (bloating, indigestion,
                      constipation/diarrhoea, irritable bowel syndrome)
                    </li>
                    <li> endocrine disorders & menopause syndrome</li>
                    <li>
                      {' '}
                      relieving various pain such as migraine, neck and shoulder
                      pain, wry neck, lower back pain, periarthritis, knee joint
                      pain, etc.
                    </li>
                  </ul>
                  Lastly, he also worked in a Sydney hospital using acupuncture
                  to help patients to reduce the various side effects (fatigue,
                  low immunity, pain, anxiety/depression) during cancer
                  treatment.
                </p>
              ) : (
                <p className='biography-body'>
                  潘子德中医师非常热衷使用中医帮助人。
                  他拥有西悉尼大学的信息技术硕士学位和悉尼科技大学的中医药学位。
                  他是澳大利亚的注册中医针灸师/中医师，也是中医药针灸学会（CMASA）的成员。
                  他认为我们的外表来自我们的内在健康，预防胜于治疗。
                  影响我们内部健康的几个重要因素是营养，情绪，睡眠质量和肠道健康。
                  他在中医针灸，草药，艾灸，拔火罐，按摩，耳针灸等方法方面具有丰富的临床经验。他的特别兴趣是：
                  <ul>
                    <li>皮肤疾病（痤疮，湿疹，牛皮癣，酒渣鼻）</li>
                    <li>失眠和情绪障碍（压力，焦虑，抑郁）</li>
                    <li>
                      美容针灸（紧张，色素沉着，肤色不均，丘疹和痤疮，干燥和暗哑，刺激胶原蛋白生成，下垂）
                    </li>
                    <li>
                      妇女的健康状况（盆腔疼痛，子宫肌瘤，多囊卵巢综合征，月经不调，痛经，不孕症）
                    </li>
                    <li>
                      消化系统疾病（腹胀，消化不良，便秘/腹泻，肠易激综合症）
                    </li>
                    <li>内分泌失调和更年期综合症</li>
                    <li>
                      缓解各种疼痛，例如偏头痛，脖子和肩膀疼痛，颈部扭伤，下背部疼痛，关节炎，膝关节疼痛等。
                    </li>
                  </ul>
                  最后，他还曾在悉尼一家医院使用针灸来帮助患者减轻癌症治疗期间的各种副作用（疲劳，免疫力低下，疼痛，焦虑/抑郁）。
                </p>
              )}
            </Col>
          </Row>
        </Container>
        <br />
      </div>
    )
  }
}

const mapStateToProps = state => {
  console.log(state)
  return {
    language: state.language.currentLanguage
  }
}

export default connect(mapStateToProps)(AboutPage)
