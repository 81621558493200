import React, { Component } from 'react'
import { Container, Row, Col, Button, Card, Accordion, Breadcrumb, Jumbotron, Image } from 'react-bootstrap'
import img1 from '../../img/treatment_img_8.jpg'
class AnxietyPage extends Component {
    state = {}
    render() {
        return (
            <div>
                <Jumbotron className="about-jumbotron" fluid>
                    <Container fluid>
                        <Row>
                            {this.props.language == "English" ? <Container fluid>
                                <h1 className="">Anxiety & Depression</h1>
                            </Container> :
                                <Container fluid>
                                    <h1 className=""> 焦虑与抑郁</h1>
                                </Container>}
                        </Row>
                    </Container>
                </Jumbotron>

                <Container>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                        <Breadcrumb.Item active>Anxiety & Depression</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row>
                        <Col>
                            <Image src={img1}
                                width="100%"
                                height="100%"
                                className="d-inline-block align-top"
                                alt="Acupuncture Treatment Image"

                            />
                        </Col>
                        <Col>
                            {this.props.language == "English" ? <p className="treatment-info-text"> Approximately, three million Australians     are living with anxiety and depression. Common symptoms are low energy, headaches, upset stomach, aches, tense muscles, chest pain, insomina and low immunity. It significantly affects our quality of life. Acupuncture is thought to help anxiety/depression by releasing endorphins i.e. natural pain killer chemicals in the brain.
                            </p> :
                                <p className="treatment-info-text"> 大约有300万澳大利亚人生活在焦虑和抑郁状态。 常见症状是精力不足，头痛，胃部不适，疼痛，肌肉紧张，胸痛，失眠和免疫力低下。 它极大地影响了我们的生活质量。 人们认为针灸可以通过释放内啡肽（即大脑中的天然止痛药）来帮助焦虑症/抑郁症
                            </p>}

                        </Col>
                    </Row>
                </Container>
                <br />
            </div>
        );
    }
}

export default AnxietyPage;