
import { combineReducers } from 'redux'

// import OrderReducer from './OrderReducer'
import LanguageReducer from './LanguageReducer'
const rootReducer = combineReducers({

    language: LanguageReducer

})

export default rootReducer