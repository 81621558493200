import React, { Component } from 'react'
import { Container, Row, Col, Button, Card, Accordion, Breadcrumb, Jumbotron, Image } from 'react-bootstrap'
import img5 from '../../img/treatment_img_9.jpg'
import { connect } from 'react-redux'

class WeightPage extends Component {
    state = {}
    render() {
        return (
            <div>
                <Jumbotron className="about-jumbotron" fluid>
                    <Container fluid>
                        <Row>
                            {this.props.language == "English" ? <Container fluid>
                                <h1 className="">Healthy Weight Program</h1>
                            </Container>
                                :
                                <Container fluid>
                                    <h1 className="">健康体重计划
</h1>
                                </Container>
                            }

                        </Row>

                    </Container>
                </Jumbotron>

                <Container>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                        <Breadcrumb.Item active>Healthy Weight Program</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row>
                        <Col>
                            <Image src={img5}
                                width="100%"
                                height="100%"
                                className="d-inline-block align-top"
                                alt="Acupuncture Treatment Image"

                            />
                        </Col>
                        <Col>
                            {this.props.language == "English" ? <p className="treatment-info-text">Healthy weight means your Body Mass Index falls within a range depending on your how big your bone skeleton is. A minor fluctuation in the scales are normal. In order to maintain healthy weight, a balanced diet with plenty of raw vegetables, regular exercise and adequate sleep are important. Chinese Medicine sees unhealthy weight gain is partly due to our constitution i.e. some people are prompt to weight gain. Based on our diagnosis, we tailor a program (combination of herbs, acupuncture, massage and cupping) for you to go back to a healthy weight range.

</p> :
                                <p className="treatment-info-text">健康的体重意味着您的身体质量指数在一定范围内，具体取决于您的骨骼大小。 秤的轻微波动是正常的。 为了保持健康的体重，均衡饮食和大量生蔬菜，定期运动和充足的睡眠很重要。 中医认为不健康的体重增加部分归因于我们的体质，即某些人会迅速增加体重。 根据我们的诊断，我们为您量身定制了一个程序（草药，针灸，按摩和拔罐相结合），以使您的体重回到健康范围。

</p>}

                        </Col>
                    </Row>
                </Container>
                <br />
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    console.log(state)
    return {
        language: state.language.currentLanguage
    }
}

export default connect(mapStateToProps)(WeightPage)