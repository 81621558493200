import React, { Component } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { connect } from 'react-redux'

class Footer extends Component {
  state = {}
  render() {
    return (
      <div className='footer'>
        <Container className='white-text '>
          {this.props.language == 'English' ? (
            <Row>
              <Col className='footer-container' md='4'>
                <p>Eddy Pun</p>
                <p>Renewed You Acupuncture Beauty Centre </p>
                <p>53 Tomah St, Carlingford NSW 2118</p>
                <p>11a, 47 Neridah St, Chatswood 2067</p>
                <p>
                  <b>Email:</b> renewyouacupuncture@gmail.com
                </p>
                <p>
                  <b>Phone Number:</b> 0421803828
                </p>
              </Col>
              <Col className='footer-container' md='2'>
                <p>
                  <a className='footer-link' href='/'>
                    Home
                  </a>
                </p>
                <p>
                  <a className='footer-link' href='/faq'>
                    FAQ
                  </a>
                </p>
                <p>
                  <a className='footer-link' href='/about'>
                    About
                  </a>
                </p>
                <p>
                  <a className='footer-link' href='/pricing'>
                    Pricing
                  </a>
                </p>
                <p>
                  <a className='footer-link' href='/contact'>
                    Contact Us
                  </a>
                </p>
              </Col>
              <Col className='footer-container' md='6'>
                <p>
                  Please text 0421803828 to make an appointment (sorry, no walk-in)
                </p>
                <Row>
                  <Col>
                    <p>
                      <b className='day'>Carlingford:</b>
                    </p>
                    <p>
                      <b className='day'>Monday:</b> 10am - 9pm
                    </p>
                    <p>
                      <b className='day'>Tuesday:</b> 10am - 9pm
                    </p>
                    <p>
                      <b className='day'>Wednesday:</b> 10am - 1pm
                    </p>
                    <p>
                      <b className='day'>Thursday:</b> 10am - 9pm
                    </p>
                    <p>
                      <b className='day'>Friday:</b> 10am - 9pm
                    </p>
                    <p>
                      <b className='day'>Saturday: </b> 9am - 1pm
                    </p>
                  </Col>
                  <Col>
                    <p>
                      <b className='day'>Chatswood:</b>
                    </p>
                    <p>
                      <b className='day'>Wednesday:</b> 2pm - 8pm
                    </p>
                    <p>
                      <b className='day'>Saturday:</b> 2pm - 6pm
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className='footer-container' md='4'>
                <p>Eddy Pun</p>
                <p>Renewed You Acupuncture Beauty Centre </p>
                <p>53 Tomah St, Carlingford NSW 2118</p>
                <p>11a, 47 Neridah St, Chatswood 2067</p>
                <p>
                  <b>电子邮件:</b> renewyouacupuncture@gmail.com
                </p>
                <p>
                  <b>电话号码:</b> 0421803828
                </p>
              </Col>
              <Col className='footer-container' md='2'>
                <p>
                  <a className='footer-link' href='/'>
                    首页{' '}
                  </a>
                </p>
                <p>
                  <a className='footer-link' href='/faq'>
                    常问问题
                  </a>
                </p>
                <p>
                  <a className='footer-link' href='/about'>
                    关于我们
                  </a>
                </p>
                <p>
                  <a className='footer-link' href='/pricing'>
                    价钱{' '}
                  </a>
                </p>
                <p>
                  <a className='footer-link' href='/contact'>
                    联系我们
                  </a>
                </p>
              </Col>
              <Col className='footer-container' md='6'>
                <p>请致电或发送电子邮件给我们提前安排约会。</p>
                <Row>
                  <Col>
                    <p>
                      <b className='day'>Carlingford:</b>
                    </p>
                    <p>
                      <b className='day'>Monday:</b> 10am - 9pm
                    </p>
                    <p>
                      <b className='day'>Tuesday:</b> 10am - 9pm
                    </p>
                    <p>
                      <b className='day'>Wednesday:</b> 10am - 1pm
                    </p>
                    <p>
                      <b className='day'>Thursday:</b> 10am - 9pm
                    </p>
                    <p>
                      <b className='day'>Friday:</b> 10am - 9pm
                    </p>
                    <p>
                      <b className='day'>Saturday: </b> 9am - 1pm
                    </p>
                  </Col>
                  <Col>
                    <p>
                      <b className='day'>Chatswood:</b>
                    </p>
                    <p>
                      <b className='day'>Wednesday:</b> 2pm - 8pm
                    </p>
                    <p>
                      <b className='day'>Saturday:</b> 2pm - 6pm
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          <div className='footer-copyright'>
            <div className='footer-container'>
              © 2020 &nbsp;Renewed You Acupuncutre Beauty Centre. Designed by{' '}
              <a href='https://www.odeo.com.au'>Odeo</a>
            </div>
          </div>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => {
  console.log(state)
  return {
    language: state.language.currentLanguage
  }
}

export default connect(mapStateToProps)(Footer)
