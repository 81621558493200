import React, { Component } from 'react'
import { Container, Row, Col, Button, Card, Accordion, Breadcrumb, Jumbotron, Image } from 'react-bootstrap'
import img6 from '../../img/treatment_img_7.jpg'
import { connect } from 'react-redux'

class InsomniaPage extends Component {
    state = {}
    render() {
        return (
            <div>
                <Jumbotron className="about-jumbotron" fluid>
                    <Container fluid>
                        <Row>
                            <Container fluid>
                                {this.props.language == "English" ? <h1 className="">Insomnia</h1> :
                                    <h1 className="">失眠</h1>
                                }

                            </Container>
                        </Row>

                    </Container>
                </Jumbotron>

                <Container>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                        <Breadcrumb.Item active>Insomnia</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row>
                        <Col>
                            <Image src={img6}
                                width="100%"
                                height="100%"
                                className="d-inline-block align-top"
                                alt="Acupuncture Treatment Image"

                            />
                        </Col>
                        <Col>
                            {this.props.language == "English" ? <p className="treatment-info-text">Insomnia could manifest into a variety of symptoms such as anxiety, depression, headaches, fatigue, loss of concentration, irritation, and suicidal ideation. As insomnia is a risk factor for developing other illnesses, treating insomnia immediately and properly could avoid the onset and reduce the severity of other diseases. Acupuncture could give good result in treating insomnia and its complications without the unwanted side effects such as addiction to the drug. The needling of local areas to calm the mind and meridian channels to regulate vital organs could be a good strategy for treating insomnia.</p>
                                :
                                <p className="treatment-info-text">失眠可能表现为多种症状，例如焦虑，抑郁，头痛，疲劳，注意力不集中，刺激和自杀意念。 由于失眠是发展其他疾病的危险因素，因此，立即正确地治疗失眠可以避免其他疾病的发作并减轻其严重程度。 针灸可以在治疗失眠及其并发症方面取得良好的效果，而不会产生诸如药物成瘾的不良副作用。 针刺局部区域以安抚心灵和调节重要器官的经络通道可能是治疗失眠的好方法。</p>

                            }
                        </Col>
                    </Row>
                </Container>
                <br />
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    console.log(state)
    return {
        language: state.language.currentLanguage
    }
}

export default connect(mapStateToProps)(InsomniaPage)