import React, { Component } from 'react'
import { Container, Row, Col, Button, Card, Accordion, Breadcrumb, Jumbotron, Image } from 'react-bootstrap'
import img4 from '../../img/treatment_img_11.jpg'
import { connect } from 'react-redux'

class CosmeticPage extends Component {
    state = {}
    render() {
        return (
            <div>
                <Jumbotron className="about-jumbotron" fluid>
                    <Container fluid>
                        <Row>
                            <Container fluid>
                                {this.props.language == "English" ? <h1 className="">Cosmetic Acupuncture</h1> :
                                    <h1 className="">美容针灸</h1>}

                            </Container>
                        </Row>

                    </Container>
                </Jumbotron>

                <Container>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                        <Breadcrumb.Item active>Cosmetic Acupuncture</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row>
                        <Col>
                            <Image src={img4}
                                width="100%"
                                height="100%"
                                className="d-inline-block align-top"
                                alt="Acupuncture Treatment Image"

                            />
                        </Col>
                        <Col>
                            <p className="treatment-info-text"> 您看起来疲倦并且想要恢复年轻光彩的皮肤吗？ 有一种安全自然的方法可以改善您的肤色和质感。 尝试美容针灸可以帮助解决以下皮肤问题，例如：
                                       <ul>
                                    <li>张力</li>
                                    <li>色素沉着和肤色不均</li>
                                    <li>青春痘和粉刺</li>
                                    <li>青春痘和粉刺</li>
                                    <li>刺激胶原蛋白的产生</li>
                                    <li>下垂和下垂</li>
                                </ul></p>
                        </Col>
                    </Row>
                </Container>
                <br />
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    console.log(state)
    return {
        language: state.language.currentLanguage
    }
}

export default connect(mapStateToProps)(CosmeticPage)
