import React, { Component } from 'react'
import Image from 'react-bootstrap/Image'
import Jumbotron from 'react-bootstrap/Jumbotron'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardDeck,
  Nav
} from 'react-bootstrap'
import rock_bg from '../img/rock-bg.jpg'
import img1 from '../img/treatment_img_8.jpg'
import img2 from '../img/treatment_img_13.jpg'
import img3 from '../img/treatment_img_10.jpg'
import img4 from '../img/treatment_img_11.jpg'
import img5 from '../img/treatment_img_9.jpg'
import img6 from '../img/treatment_img_7.jpg'
import img7 from '../img/treatment_img_5.jpg'
import img8 from '../img/treatment_img_12.jpg'
import cert1 from '../img/uts-logo.jpg'
import cert2 from '../img/cmasa-logo.jpg'
import TextTruncate from 'react-text-truncate'
import { connect } from 'react-redux'
class Homepage extends Component {
  state = {
    isHidden: true
  }
  render () {
    return (
      <div>
        <Jumbotron className='background-jumbotron' fluid>
          <Container fluid>
            <Row className='jumbotron-row'>
              <Container fluid>
                <Col md={6}>
                  {this.props.language == 'English' ? (
                    <div>
                      <h1 className='white-text'>Renew Yourself</h1>
                      <br />
                      <h3 className='white-text'>
                        Believe you can, and you're already halfway there.
                      </h3>
                      <Button
                        variant='outline-light'
                        size='lg'
                        style={{
                          marginTop: 25,

                          fontWeight: 450,
                          fontSize: 28
                        }}
                        href='https://www.fresha.com/book-now/renew-you-acupuncture-beauty-centre-oui2rws5/all-offer?pId=628910'
                      >
                        Book Now
                      </Button>{' '}
                    </div>
                  ) : (
                    <div>
                      <h1 className='white-text'>更新自己</h1>
                      <br />
                      <h3 className='white-text'>
                        相信您可以，您已经成功了一半。
                      </h3>
                      <Button
                        href='https://www.fresha.com/book-now/renew-you-acupuncture-beauty-centre-oui2rws5/all-offer?pId=628910'
                        variant='outline-light'
                        size='lg'
                        style={{
                          marginTop: 25,

                          fontWeight: 450,
                          fontSize: 28
                        }}
                      >
                        Book Now
                      </Button>{' '}
                    </div>
                  )}
                  <br />
                </Col>
              </Container>
            </Row>
          </Container>
        </Jumbotron>

        <Container className='info-container' fluid>
          <Row className='inspirational-row justify-content-md-center'>
            {this.props.language == 'English' ? (
              <Col xs lg='6'>
                <h2>
                  Welcome to the <i>Renewed You Acupuncture Beauty Centre</i>
                </h2>

                <p id='inspirational-msg'>
                  We believe that you have the right to feel good, no matter
                  what is going on in your life or what has happened in the
                  past. We believe that there are many things that we can do to
                  make your body and mind feel better, from acupuncture, herbal
                  medicines, cupping, guasha, moxa, dietary advice right through
                  to life coaching.
                  <br />
                  We can help you feel refreshed again!
                </p>
              </Col>
            ) : (
              <Col xs lg='6'>
                <h2>欢迎来到更新的您针灸美容中心</h2>

                <p id='inspirational-msg'>
                  欢迎来到更新的您针灸美容中心我们相信，无论您的生活正在发生或过去发生了什么，您都有权享有良好的感觉。
                  我们相信，从针灸，草药，拔罐，刮痧，艾灸，饮食建议到生活指导，我们有许多事情可以使您的身心得到改善。
                  <br />
                  我们可以帮助您再次感到精神焕发！
                </p>
              </Col>
            )}
          </Row>
          <br />
          <Row className='justify-content-md-center'>
            <h1>
              <b>Treatments We Offer</b>
            </h1>
          </Row>
          <br />
          <Container>
            <CardDeck>
              <Card>
                <Card.Img variant='top' src={img6} />
                {this.props.language == 'English' ? (
                  <Card.Body>
                    <Card.Title>Insomnia</Card.Title>
                    <Card.Text>
                      <TextTruncate
                        line={5}
                        element='p'
                        truncateText='...'
                        text=' Insomnia could manifest into a variety of symptoms such as anxiety, depression, headaches, fatigue, loss of concentration, irritation, and suicidal ideation. As insomnia is a risk factor for developing other illnesses, treating insomnia immediately and properly could avoid the onset and reduce the severity of other diseases. Acupuncture could give good result in treating insomnia and its complications without the unwanted side effects such as addiction to the drug. The needling of local areas to calm the mind and meridian channels to regulate vital organs could be a good strategy for treating insomnia.'
                        textTruncateChild={<a href='/insomnia'>Read on</a>}
                      />
                    </Card.Text>
                  </Card.Body>
                ) : (
                  <Card.Body>
                    <Card.Title>失眠</Card.Title>
                    <Card.Text>
                      <TextTruncate
                        line={5}
                        element='p'
                        truncateText='...'
                        text='  失眠可能表现为多种症状，例如焦虑，抑郁，头痛，疲劳，注意力不集中，刺激和自杀意念。 由于失眠是发展其他疾病的危险因素，因此，立即正确地治疗失眠可以避免其他疾病的发作并减轻其严重程度。 针灸可以在治疗失眠及其并发症方面取得良好的效果，而不会产生诸如药物成瘾的不良副作用。 针刺局部区域以安抚心灵和调节重要器官的经络通道可能是治疗失眠的好方法。'
                        textTruncateChild={<a href='/insomnia'>继续阅读</a>}
                      />
                    </Card.Text>
                  </Card.Body>
                )}
                {/* <Card.Footer>
                                <Nav.Link href="/contact">Book Now</Nav.Link>
                            </Card.Footer> */}
              </Card>
              <Card>
                <Card.Img variant='top' src={img2} />
                {this.props.language == 'English' ? (
                  <Card.Body>
                    <Card.Title>Dermatology</Card.Title>

                    <Card.Text>
                      <TextTruncate
                        line={5}
                        element='p'
                        truncateText='...'
                        text='Common skin disorders include acne, eczema, psoriasis and hives. It is quite debilitating if someone has a chronic skin condition. As our skin reflects what is in our internal health, we use herbal medicine and acupuncture to improve and regulate our internal health. Apart from oral herbal medicine, external ointment application and dietary advice would also help to speed up the healing process. As a result, you can regain back a healthy skin and self-confidence.'
                        textTruncateChild={<a href='/dermatology'>Read on</a>}
                      />
                    </Card.Text>
                  </Card.Body>
                ) : (
                  <Card.Body>
                    <Card.Title>皮肤科</Card.Title>

                    <Card.Text>
                      <TextTruncate
                        line={5}
                        element='p'
                        truncateText='...'
                        text='常见的皮肤疾病包括痤疮，湿疹，牛皮癣和荨麻疹。 如果某人患有慢性皮肤病，那将非常令人沮丧。 由于我们的皮肤反映了我们内部健康的状况，因此我们使用草药和针灸来改善和调节我们的内部健康。 除了口服草药外，外用药膏和饮食建议也将有助于加快愈合过程。 结果，您可以恢复健康的皮肤和自信。'
                        textTruncateChild={<a href='/dermatology'>继续阅读</a>}
                      />
                    </Card.Text>
                  </Card.Body>
                )}
                {/* <Card.Footer>
                                <Nav.Link href="/contact">Book Now</Nav.Link>
                            </Card.Footer> */}
              </Card>
              <Card>
                <Card.Img variant='top' src={img4} />
                {this.props.language == 'English' ? (
                  <Card.Body>
                    <Card.Title>Cosmetic Acupuncture</Card.Title>
                    <Card.Text>
                      <TextTruncate
                        line={5}
                        element='p'
                        truncateText='...'
                        text='   Do you look tired and want to regain a youthful and glowing skin?
                                        There is a safe and natural way to improve your skin tone and texture.  Try cosmetic acupuncture which could help with the following skin problems such as:
                                       <ul>
                                            <li>Tension</li>
                                            <li>Pigmentation and uneven skin tone</li>
                                            <li>Pimples and acne</li>
                                            <li>Dryness and dullness</li>
                                            <li>Stimulate Collagen production</li>
                                            <li>Drooping and sagging</li>
                                        </ul>'
                        textTruncateChild={<a href='/cosmetic'>Read on</a>}
                      />
                    </Card.Text>
                  </Card.Body>
                ) : (
                  <Card.Body>
                    <Card.Title>美容针灸</Card.Title>
                    <Card.Text>
                      <TextTruncate
                        line={5}
                        element='p'
                        truncateText='...'
                        text='  您看起来疲倦并且想要恢复年轻光彩的皮肤吗？ 有一种安全自然的方法可以改善您的肤色和质感。 尝试美容针灸可以帮助解决以下皮肤问题，例如：
                                       <ul>
                                            <li>张力</li>
                                            <li>色素沉着和肤色不均</li>
                                            <li>青春痘和粉刺</li>
                                            <li>青春痘和粉刺</li>
                                            <li>刺激胶原蛋白的产生</li>
                                            <li>下垂和下垂</li>
                                        </ul>'
                        textTruncateChild={<a href='/cosmetic'>继续阅读</a>}
                      />
                    </Card.Text>
                  </Card.Body>
                )}
                {/* <Card.Footer>
                                <Nav.Link href="/contact">Book Now</Nav.Link>
                            </Card.Footer> */}
              </Card>
              <Card>
                <Card.Img variant='top' src={img3} />
                {this.props.language == 'English' ? (
                  <Card.Body>
                    <Card.Title>Infertility / Women's Health</Card.Title>
                    <Card.Text>
                      <TextTruncate
                        line={5}
                        element='p'
                        truncateText='...'
                        text=' The prevalence of infertility is estimated to be 9% with 56% of couples seeking medical care/treatment. Nowadays, couples in developed countries delay having children until they have more financial security. Hence, age-related infertility becomes more common in developed countries. Chinese medicine aims to identify the root cause of infertility and tries to regulate the internal conditions by acupuncture and herbs so that couples can have more chances of conceiving. If you are considering having a baby and want to try non-aggressive treatment, you can make an appointment for a consultation.'
                        textTruncateChild={<a href='/womens-health'>Read on</a>}
                      />
                    </Card.Text>
                  </Card.Body>
                ) : (
                  <Card.Body>
                    <Card.Title>不孕症/女性健康</Card.Title>
                    <Card.Text>
                      <TextTruncate
                        line={5}
                        element='p'
                        truncateText='...'
                        text=' 不孕症的患病率估计为9％，其中56％的夫妇寻求医疗/治疗。 如今，发达国家的夫妇推迟生育孩子，直到他们有更多的经济保障为止。 因此，与年龄有关的不育症在发达国家越来越普遍。 中医的目的是找出不孕症的根本原因，并尝试通过针灸和草药来调节内部状况，以便夫妻有更多的受孕机会。 如果您正在考虑生孩子并想尝试非侵略性治疗，可以预约进行咨询。'
                        textTruncateChild={
                          <a href='/womens-health'>继续阅读</a>
                        }
                      />
                    </Card.Text>
                  </Card.Body>
                )}
                {/* <Card.Footer>
                                <Nav.Link href="/contact">Book Now</Nav.Link>
                            </Card.Footer> */}
              </Card>
            </CardDeck>
            <br />
            <CardDeck>
              <Card>
                <Card.Img variant='top' src={img1} />
                {this.props.language == 'English' ? (
                  <Card.Body>
                    <Card.Title>Anxiety & Depression</Card.Title>
                    <Card.Text className='truncate-overflow'>
                      <TextTruncate
                        line={5}
                        element='p'
                        truncateText='...'
                        text='Approximately, three million Australians are living with anxiety and depression. Common symptoms are low energy, headaches, upset stomach, aches, tense muscles, chest pain, insomina and low immunity. It significantly affects our quality of life. Acupuncture is thought to help anxiety/depression by releasing endorphins i.e. natural pain killer chemicals in the brain.'
                        textTruncateChild={<a href='/anxiety'>Read on</a>}
                      />
                    </Card.Text>
                  </Card.Body>
                ) : (
                  <Card.Body>
                    <Card.Title>焦虑与抑郁</Card.Title>
                    <Card.Text className='truncate-overflow'>
                      <TextTruncate
                        line={5}
                        element='p'
                        truncateText='...'
                        text='大约有300万澳大利亚人生活在焦虑和抑郁状态。 常见症状是精力不足，头痛，胃部不适，疼痛，肌肉紧张，胸痛，失眠和免疫力低下。 它极大地影响了我们的生活质量。 人们认为针灸可以通过释放内啡肽（即大脑中的天然止痛药）来帮助焦虑症/抑郁症'
                        textTruncateChild={<a href='/anxiety'>继续阅读</a>}
                      />
                    </Card.Text>
                  </Card.Body>
                )}
                {/* <Card.Footer>
                                <Nav.Link href="/contact">Book Now</Nav.Link>
                            </Card.Footer> */}
              </Card>
              <Card>
                <Card.Img variant='top' src={img8} />
                {this.props.language == 'English' ? (
                  <Card.Body>
                    <Card.Title>Digestive Disorders</Card.Title>
                    <Card.Text>
                      <TextTruncate
                        line={5}
                        element='p'
                        truncateText='...'
                        text=' Digestive disorders include conditions such as indigestion, nausea, vomiting, acid Reflux, burping, bloating, constipation/loose stool and abdominal pain. Chinese herbs are indicated to help the digestive organs such as Stomach/Spleen, Liver/Gall Bladder and Small/Large Intestine to restore the movement of Qi, improve breakdown of food, uptake and transformation of nutrients and eliminate toxin.  '
                        textTruncateChild={
                          <a href='/digestive-disorder'>Read on</a>
                        }
                      />
                    </Card.Text>
                  </Card.Body>
                ) : (
                  <Card.Body>
                    <Card.Title>消化系统疾病</Card.Title>
                    <Card.Text>
                      <TextTruncate
                        line={5}
                        element='p'
                        truncateText='...'
                        text='消化系统疾病包括消化不良，恶心，呕吐，胃酸反流，打bur，腹胀，便秘/大便稀疏和腹痛等病症。 中草药可以帮助消化器官，例如胃/脾脏，肝/胆囊和小肠/大肠，恢复气的运动，改善食物分解，营养的吸收和转化并消除毒素。 '
                        textTruncateChild={
                          <a href='/digestive-disorder'>继续阅读</a>
                        }
                      />
                    </Card.Text>
                  </Card.Body>
                )}
                {/* <Card.Footer>
                                <Nav.Link href="/contact">Book Now</Nav.Link>
                            </Card.Footer> */}
              </Card>

              <Card>
                <Card.Img variant='top' src={img7} />
                {this.props.language == 'English' ? (
                  <Card.Body>
                    <Card.Title>Acute & Chronic Pain</Card.Title>
                    <Card.Text>
                      <TextTruncate
                        line={5}
                        element='p'
                        truncateText='...'
                        text='Are you suffering from muscloskeletal problems, migraine, period pain, chest pain, palpitation, sports injury, stiff muscle, nerve pain, pain caused by cancer treatment, etc. We use different modalities to give you pain relief. Acupuncture, Guasha, Cupping, herbal medicine might be used in combination to reduce your pain so you can get on with your daily life.
                                     '
                        textTruncateChild={<a href='/acute-pain'>Read on</a>}
                      />
                    </Card.Text>
                  </Card.Body>
                ) : (
                  <Card.Body>
                    <Card.Title>急性和慢性疼痛</Card.Title>
                    <Card.Text>
                      <TextTruncate
                        line={5}
                        element='p'
                        truncateText='...'
                        text='您是否患有肌肉骨骼疾病，偏头痛，经期疼痛，胸痛，心慌，运动损伤，僵硬的肌肉，神经痛，癌症治疗引起的疼痛等。我们使用不同的方法来减轻疼痛。 针灸，刮痧，拔罐，草药可以组合使用，以减轻疼痛，使您的日常生活更加轻松。
                                     '
                        textTruncateChild={<a href='/acute-pain'>继续阅读</a>}
                      />
                    </Card.Text>
                  </Card.Body>
                )}
                {/* <Card.Footer>
                                <Nav.Link href="/contact">Book Now</Nav.Link>
                            </Card.Footer> */}
              </Card>

              <Card>
                <Card.Img variant='top' src={img5} />
                {this.props.language == 'English' ? (
                  <Card.Body>
                    <Card.Title>Healthy Weight Program</Card.Title>
                    <Card.Text>
                      <TextTruncate
                        line={5}
                        element='p'
                        truncateText='...'
                        text='Healthy weight means your Body Mass Index falls within a range depending on your how big your bone skeleton is. A minor fluctuation in the scales are normal. In order to maintain healthy weight, a balanced diet with plenty of raw vegetables, regular exercise and adequate sleep are important. Chinese Medicine sees unhealthy weight gain is partly due to our constitution i.e. some people are prompt to weight gain. Based on our diagnosis, we tailor a program (combination of herbs, acupuncture, massage and cupping) for you to go back to a healthy weight range.'
                        textTruncateChild={
                          <a href='/healthy-weight'>Read on</a>
                        }
                      />
                    </Card.Text>
                  </Card.Body>
                ) : (
                  <Card.Body>
                    <Card.Title>健康体重计划</Card.Title>
                    <Card.Text>
                      <TextTruncate
                        line={5}
                        element='p'
                        truncateText='...'
                        text='健康的体重意味着您的身体质量指数在一定范围内，具体取决于您的骨骼大小。 秤的轻微波动是正常的。 为了保持健康的体重，均衡饮食和大量生蔬菜，定期运动和充足的睡眠很重要。 中医认为不健康的体重增加部分归因于我们的体质，即某些人会迅速增加体重。 根据我们的诊断，我们为您量身定制了一个程序（草药，针灸，按摩和拔罐相结合），以使您的体重回到健康范围。'
                        textTruncateChild={
                          <a href='/healthy-weight'>继续阅读</a>
                        }
                      />
                    </Card.Text>
                  </Card.Body>
                )}
                {/* <Card.Footer>
                                <Nav.Link href="/contact">Book Now</Nav.Link>
                            </Card.Footer> */}
              </Card>
            </CardDeck>
          </Container>
          <br />
          <Row className='justify-content-md-center'>
            {this.props.language == 'English' ? (
              <h1>
                <b>Licenses and Certifications</b>
              </h1>
            ) : (
              <h1>
                <b>许可证和证明</b>
              </h1>
            )}
          </Row>
          <br />
          {this.props.language == 'English' ? (
            <Container>
              <p>
                <b>AHPRA Registration number:</b> CMR0002243249
              </p>
              <p>
                <b>
                  As Acupuncturist, Chinese Herbal Medicine Practitioner,
                  Chinese Herbal Dispenser
                </b>
              </p>

              <img
                src={cert2}
                width='25%'
                height='50%'
                className='d-inline-block align-top cert-img'
                alt='Acupuncture Treatment Image'
              />
            </Container>
          ) : (
            <Container>
              <p>
                <b>AHPRA 注册号:</b> CMR0002243249
              </p>
              <p>
                <b>针灸师，中医师，中药药剂师</b>
              </p>

              <img
                src={cert2}
                width='25%'
                height='50%'
                className='d-inline-block align-top cert-img'
                alt='Acupuncture Treatment Image'
              />
            </Container>
          )}
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => {
  console.log(state)
  return {
    language: state.language.currentLanguage
  }
}

export default connect(mapStateToProps)(Homepage)
