import React, { Component } from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import logo from '../img/logo195.png'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { changeLanguage } from './Store/LanguageActions'
class MyNavbar extends Component {
  state = {}

  handleChineseSwitch = e => {
    console.log('Language Swapped to Chinese')
    this.props.changeLanguage('中文')
  }
  handleEnglishSwitch = e => {
    console.log('Language Swapped to English')
    this.props.changeLanguage('English')
  }
  render() {
    return (
      <Navbar variant='light' expand='lg' bg='white'>
        <Navbar.Brand href='/'>
          <img
            src={logo}
            width='200'
            height='100'
            className='d-inline-block align-top'
            alt='React Bootstrap logo'
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        {this.props.language == 'English' ? (
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='mr-auto'>
              <Nav.Link href='/'>
                <b>Home</b>
              </Nav.Link>
              <Nav.Link href='/faq'>
                <b>FAQ</b>
              </Nav.Link>
              <Nav.Link href='/about'>
                <b>About Us</b>
              </Nav.Link>
              <Nav.Link href='/pricing'>
                <b>Pricing</b>
              </Nav.Link>
              <NavDropdown title={<b>Treatments</b>} id='basic-nav-dropdown'>
                <NavDropdown.Item href='/anxiety'>
                  Anxiety & Depression
                </NavDropdown.Item>
                <NavDropdown.Item href='/healthy-weight'>
                  Healthy Weight Program
                </NavDropdown.Item>
                <NavDropdown.Item href='/digestive-disorder'>
                  Digestive Disorders
                </NavDropdown.Item>
                <NavDropdown.Item href='/womens-health'>
                  Infertility / Women's Health
                </NavDropdown.Item>
                <NavDropdown.Item href='/cosmetic'>
                  Cosmetic Acupuncture
                </NavDropdown.Item>
                <NavDropdown.Item href='/dermatology'>
                  Dermatology
                </NavDropdown.Item>
                <NavDropdown.Item href='/insomnia'>
                  Insomnia Treatments
                </NavDropdown.Item>
                <NavDropdown.Item href='/acute-pain'>
                  Acute & Chronic Pain
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav>
              <NavDropdown
                title={<b>{this.props.language}</b>}
                id='basic-nav-dropdown'
              >
                <NavDropdown.Item onClick={this.handleEnglishSwitch}>
                  English
                </NavDropdown.Item>
                <NavDropdown.Item onClick={this.handleChineseSwitch}>
                  中文
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href='/contact'>
                <b>Contact Us</b>
              </Nav.Link>
              <Nav.Link href='/contact'>
                {this.props.language === 'English' ? (
                  <div>
                    <Button
                      variant='secondary'
                      size='sm'
                      style={{
                        marginLeft: 5,
                        marginTop: -5,
                        fontWeight: 450,
                        fontSize: 16
                      }}
                      href='/contact'
                    >
                      Book Now
                    </Button>{' '}
                  </div>
                ) : (
                  <div>
                    <Button
                      href='/contact'
                      variant='secondary'
                      size='sm'
                      style={{
                        marginLeft: 5,
                        marginTop: -5,
                        fontWeight: 450,
                        fontSize: 16
                      }}
                    >
                      Book Now
                    </Button>{' '}
                  </div>
                )}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        ) : (
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='mr-auto'>
              <Nav.Link href='/'>
                <b>首页 </b>
              </Nav.Link>
              <Nav.Link href='/faq'>
                <b>常问问题</b>
              </Nav.Link>
              <Nav.Link href='/about'>
                <b>关于我们 </b>
              </Nav.Link>
              <Nav.Link href='/pricing'>
                <b>价钱</b>
              </Nav.Link>
              <NavDropdown title={<b>治疗方法</b>} id='basic-nav-dropdown'>
                <NavDropdown.Item href='/anxiety'>焦虑与抑郁</NavDropdown.Item>
                <NavDropdown.Item href='/healthy-weight'>
                  健康体重计划
                </NavDropdown.Item>
                <NavDropdown.Item href='/digestive-disorder'>
                  消化系统疾病
                </NavDropdown.Item>
                <NavDropdown.Item href='/womens-health'>
                  不孕症/女性健康
                </NavDropdown.Item>
                <NavDropdown.Item href='/cosmetic'>美容针灸</NavDropdown.Item>
                <NavDropdown.Item href='/dermatology'>皮肤科</NavDropdown.Item>
                <NavDropdown.Item href='/insomnia'>失眠</NavDropdown.Item>
                <NavDropdown.Item href='/acute-pain'>
                  急性和慢性疼痛
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav>
              <NavDropdown
                title={<b>{this.props.language}</b>}
                id='basic-nav-dropdown'
              >
                <NavDropdown.Item onClick={this.handleEnglishSwitch}>
                  English
                </NavDropdown.Item>
                <NavDropdown.Item onClick={this.handleChineseSwitch}>
                  中文
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href='/contact'>
                <b>联系我们</b>
              </Nav.Link>
              <Nav.Link href='/contact'>
                {this.props.language === 'English' ? (
                  <div>
                    <Button
                      variant='secondary'
                      size='sm'
                      style={{
                        marginLeft: 5,
                        marginTop: -5,
                        fontWeight: 450,
                        fontSize: 16
                      }}
                      href='/contact'
                    >
                      Book Now
                    </Button>{' '}
                  </div>
                ) : (
                  <div>
                    <Button
                      href='/contact'
                      variant='secondary'
                      size='sm'
                      style={{
                        marginLeft: 5,
                        marginTop: -5,
                        fontWeight: 450,
                        fontSize: 18,
                        paddingLeft: 20,
                        paddingRight: 20
                      }}
                    >
                      預約
                    </Button>{' '}
                  </div>
                )}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        )}
      </Navbar>
    )
  }
}
const mapStateToProps = state => {
  console.log(state)
  return {
    language: state.language.currentLanguage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeLanguage: language => dispatch(changeLanguage(language))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyNavbar)
