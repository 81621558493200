import React, { Component } from 'react'
import { Container, Row, Col, Button, Card, Accordion, Breadcrumb, Jumbotron, Image } from 'react-bootstrap'
import img2 from '../../img/treatment_img_13.jpg'
import { connect } from 'react-redux'

class DermatologyPage extends Component {
    state = {}
    render() {
        return (
            <div>
                <Jumbotron className="about-jumbotron" fluid>
                    <Container fluid>
                        <Row>
                            <Container fluid>
                                {this.props.language == "English" ? <h1 className="">Dermatology</h1> :
                                    <h1 className="">皮肤科</h1>
                                }

                            </Container>
                        </Row>

                    </Container>
                </Jumbotron>

                <Container>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                        <Breadcrumb.Item active>Dermatology</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row>
                        <Col>
                            <Image src={img2}
                                width="100%"
                                height="100%"
                                className="d-inline-block align-top"
                                alt="Acupuncture Treatment Image"

                            />
                        </Col>
                        <Col>
                            {this.props.language == "English" ? <p className="treatment-info-text">Common skin disorders include acne, eczema,  psoriasis and hives. It is quite debilitating if someone has a chronic skin condition. As our skin reflects what is in our internal health, we use herbal medicine and acupuncture to improve and regulate our internal health. Apart from oral herbal medicine, external ointment application and dietary advice  would also help to speed up the healing process. As a result, you can regain back a healthy skin and self-confidence.</p>
                                :
                                <p className="treatment-info-text">常见的皮肤疾病包括痤疮，湿疹，牛皮癣和荨麻疹。 如果某人患有慢性皮肤病，那将非常令人沮丧。 由于我们的皮肤反映了我们内部健康的状况，因此我们使用草药和针灸来改善和调节我们的内部健康。 除了口服草药外，外用药膏和饮食建议也将有助于加快愈合过程。 结果，您可以恢复健康的皮肤和自信。</p>
                            }
                        </Col>
                    </Row>
                </Container>
                <br />
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    console.log(state)
    return {
        language: state.language.currentLanguage
    }
}

export default connect(mapStateToProps)(DermatologyPage)
