import React, { Component } from 'react'
import { Container, Row, Col, Button, Card, Accordion, Breadcrumb, Jumbotron, Image } from 'react-bootstrap'
import img3 from '../../img/treatment_img_10.jpg'
import { connect } from 'react-redux'

class InfertilityPage extends Component {
    state = {}
    render() {
        return (
            <div>
                <Jumbotron className="about-jumbotron" fluid>
                    <Container fluid>
                        <Row>
                            <Container fluid>
                                {this.props.language == "English" ? <h1 className="">Infertility & Women's Health</h1> :
                                    <h1 className="">不孕症/女性健康</h1>
                                }

                            </Container>
                        </Row>

                    </Container>
                </Jumbotron>

                <Container>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                        <Breadcrumb.Item active>Infertility & Women's Health</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row>
                        <Col>
                            <Image src={img3}
                                width="100%"
                                height="100%"
                                className="d-inline-block align-top"
                                alt="Acupuncture Treatment Image"

                            />
                        </Col>
                        <Col>
                            {this.props.language == "English" ? <p className="treatment-info-text">The prevalence of infertility is estimated to be 9% with 56% of couples seeking medical care/treatment. Nowadays, couples in developed countries delay having children until they have more financial security. Hence, age-related infertility becomes more common in developed countries. Chinese medicine aims to identify the root cause of infertility and tries to regulate the internal conditions by acupuncture and herbs so that couples can have more chances of conceiving. If you are considering having a baby and want to try non-aggressive treatment, you can make an appointment for a consultation.</p>
                                :
                                <p className="treatment-info-text">不孕症的患病率估计为9％，其中56％的夫妇寻求医疗/治疗。 如今，发达国家的夫妇推迟生育孩子，直到他们有更多的经济保障为止。 因此，与年龄有关的不育症在发达国家越来越普遍。 中医的目的是找出不孕症的根本原因，并尝试通过针灸和草药来调节内部状况，以便夫妻有更多的受孕机会。 如果您正在考虑生孩子并想尝试非侵略性治疗，可以预约进行咨询。 </p>
                            }
                        </Col>
                    </Row>
                </Container>
                <br />
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    console.log(state)
    return {
        language: state.language.currentLanguage
    }
}

export default connect(mapStateToProps)(InfertilityPage)