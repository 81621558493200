import React, { Component } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Accordion,
  Breadcrumb,
  Jumbotron,
  Table
} from 'react-bootstrap'
import { connect } from 'react-redux'

class PricingPage extends Component {
  state = {}
  render() {
    return (
      <div>
        <Jumbotron className='about-jumbotron' fluid>
          <Container fluid>
            <Row>
              <Container fluid>
                {this.props.language == 'English' ? (
                  <h1 className=''>Pricing</h1>
                ) : (
                  <h1 className=''>价钱 </h1>
                )}
              </Container>
            </Row>
          </Container>
        </Jumbotron>
        <Container>
          {this.props.language == 'English' ? (
            <Breadcrumb>
              <Breadcrumb.Item href='/'>Home</Breadcrumb.Item>

              <Breadcrumb.Item active>Pricing</Breadcrumb.Item>
            </Breadcrumb>
          ) : (
            <Breadcrumb>
              <Breadcrumb.Item href='/'>首页 </Breadcrumb.Item>

              <Breadcrumb.Item active>价钱 </Breadcrumb.Item>
            </Breadcrumb>
          )}
          <br />
          {this.props.language == 'English' ? (
            <h2>Acupuncture & Other Modalities</h2>
          ) : (
            <h2>针灸及其他服务</h2>
          )}
          <br />
          <Row>
            {this.props.language == 'English' ? (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Service</th>
                    <th> Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Acupuncture</td>
                    <td>$70 for one condition <br />
                      $85 for two conditions<br />
                      $100 for three conditions</td>
                  </tr>

                  <tr>
                    <td>Cosmetic Acupuncture</td>
                    <td colSpan='2'>$100 (including facial Guasha)</td>
                  </tr>

                  <tr>
                    <td>Cupping</td>
                    <td colSpan='2'>$30 (per 15 minutes)</td>
                  </tr>
                  <tr>
                    <td>Guasha</td>
                    <td colSpan='2'>$55 (per 30 minutes)</td>
                  </tr>
                  <tr>
                    <td>Massage</td>
                    <td colSpan='2'>$55 (per 30 minutes)</td>
                  </tr>
                  <tr>
                    <td>Moxa</td>
                    <td colSpan='2'>$55 (per 30 minutes)</td>
                  </tr>
                </tbody>
              </Table>
            ) : (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>服务</th>
                    <th> 价格</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>初始针灸</td>
                    <td>
                      一种条件70美元<br />
                      两种条件85美元<br />
                      三种条件100美元</td>
                  </tr>

                  <tr>
                    <td>初始美容针灸</td>
                    <td colSpan='2'>$100</td>
                  </tr>

                  <tr>
                    <td>拔罐</td>
                    <td colSpan='2'>$30 (每15分钟)</td>
                  </tr>
                  <tr>
                    <td>刮痧</td>
                    <td colSpan='2'>$55 (每30分钟)</td>
                  </tr>
                  <tr>
                    <td>按摩</td>
                    <td colSpan='2'>$55 (每30分钟)</td>
                  </tr>
                  <tr>
                    <td>艾灸</td>
                    <td colSpan='2'>$55 (每30分钟)</td>
                  </tr>
                </tbody>
              </Table>
            )}
          </Row>

          <br />
          {this.props.language == 'English' ? (
            <h2>Chinese Herbal Medicine</h2>
          ) : (
            <h2>中草药</h2>
          )}
          <br />
          <Row>
            {this.props.language == 'English' ? (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Service</th>
                    <th> Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Herbal Consultation</td>
                    <td>$30 for 20mins consultation<br />
                      $40 for over 20mins consultation</td>
                  </tr>
                  <tr>
                    <td>Herbs</td>
                    <td colSpan='2'>
                      $20 for a bottle of herbal pills <br />
                      From $0.8 per grams for herbal powder

                    </td>
                  </tr>
                </tbody>
              </Table>
            ) : (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>服务</th>
                    <th> 价格</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>初始草药咨询</td>
                    <td>20分钟咨询费用为30美元<br />
                      超过20分钟的咨询费用为40美元</td>
                  </tr>

                  <tr>
                    <td>草药</td>
                    <td colSpan='2'>草药丸一瓶20美元 <br />
                      草药粉每克从0.8美元起中药的价格从每周$ 18（药丸）到$ 70（草药草）不等
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
          </Row>
        </Container>
        <br />
      </div>
    )
  }
}

const mapStateToProps = state => {
  console.log(state)
  return {
    language: state.language.currentLanguage
  }
}

export default connect(mapStateToProps)(PricingPage)
