const initState = {
    currentLanguage: "English"
}

const languageReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CHANGE_LANGUAGE':
            console.log("switched language", action.language)
            state["currentLanguage"] = action.language
            return {
                ...state,
            };
        default:
        // console.log(state)
    }
    return state;
}

export default languageReducer