import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import {
  Container,
  Jumbotron,
  Row,
  CardDeck,
  Card,
  Breadcrumb,
  Col
} from 'react-bootstrap'
import Marker from './Marker'
import { connect } from 'react-redux'

class ContactPage extends Component {
  state = {}
  getMapOptions = maps => {
    return {
      disableDefaultUI: true,
      mapTypeControl: true,
      streetViewControl: true,
      styles: [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'on' }]
        }
      ]
    }
  }

  render() {
    return (
      <div>
        <Jumbotron className='contact-jumbotron' fluid>
          <Container fluid>
            <Row>
              <Container fluid>
                {this.props.language == 'English' ? (
                  <h1 className=''>Contact Us</h1>
                ) : (
                  <h1 className=''>联系我们</h1>
                )}
              </Container>
            </Row>
          </Container>
        </Jumbotron>
        <Container>
          <Breadcrumb>
            <Breadcrumb.Item href='/'>Home</Breadcrumb.Item>

            <Breadcrumb.Item active>Contact</Breadcrumb.Item>
          </Breadcrumb>
        </Container>
        {this.props.language == 'English' ? (
          <Container>
            <CardDeck>
              <Card>
                {/* <Card.Img variant="top" src={img5} /> */}
                <Card.Body>
                  <Card.Title>Contact Details</Card.Title>
                  <Card.Text>
                    <p>
                      <b>Phone Number:</b> 0421803828
                    </p>
                    <p>
                      <b>Email:</b> renewyouacupuncture@gmail.com
                    </p>
                    <p>
                      <b>Carlingford Address:</b> 53 Tomah St, Carlingford NSW
                      2118
                    </p>
                    <p>
                      <b>Chatswood Address:</b> 11a, 47 Neridah St, Chatswood
                      2067
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                {/* <Card.Img variant="top" src={img5} /> */}
                <Card.Body>
                  <Card.Title>Opening Hours</Card.Title>
                  <Card.Text>
                    <p>
                      Please text 0421803828 to make an appointment (sorry, no walk-in)
                    </p>
                    <Row>
                      <Col>
                        <p>
                          <b className='day'>Carlingford:</b>
                        </p>
                        <p>
                          <b className='day'>Monday:</b> 10am - 9pm
                        </p>
                        <p>
                          <b className='day'>Tuesday:</b> 10am - 9pm
                        </p>
                        <p>
                          <b className='day'>Wednesday:</b> 10am - 1pm
                        </p>
                        <p>
                          <b className='day'>Thursday:</b> 10am - 9pm
                        </p>
                        <p>
                          <b className='day'>Friday:</b> 10am - 9pm
                        </p>
                        <p>
                          <b className='day'>Saturday: </b> 9am - 1pm
                        </p>
                      </Col>
                      <Col>
                        <p>
                          <b className='day'>Chatswood:</b>
                        </p>
                        <p>
                          <b className='day'>Wednesday:</b> 2pm - 8pm
                        </p>
                        <p>
                          <b className='day'>Saturday:</b> 2pm - 6pm
                        </p>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck>
          </Container>
        ) : (
          <Container>
            <CardDeck>
              <Card>
                {/* <Card.Img variant="top" src={img5} /> */}
                <Card.Body>
                  <Card.Title>联系方式</Card.Title>
                  <Card.Text>
                    <p>
                      <b>电话号码:</b> 0421803828
                    </p>
                    <p>
                      <b>电子邮件:</b> renewyouacupuncture@gmail.com
                    </p>
                    <p>
                      <b>地址:</b> 新南威尔士州2118，卡林福德，Tomah St 53
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                {/* <Card.Img variant="top" src={img5} /> */}
                <Card.Body>
                  <Card.Title>营业时间</Card.Title>
                  <Card.Text>
                    <p>请致电或发送电子邮件给我们提前安排约会。</p>
                    <Row>
                      <Col>
                        <p>
                          <b className='day'>Carlingford:</b>
                        </p>
                        <p>
                          <b className='day'>Monday:</b> 10am - 9pm
                        </p>
                        <p>
                          <b className='day'>Tuesday:</b> 10am - 9pm
                        </p>
                        <p>
                          <b className='day'>Wednesday:</b> 10am - 1pm
                        </p>
                        <p>
                          <b className='day'>Thursday:</b> 10am - 9pm
                        </p>
                        <p>
                          <b className='day'>Friday:</b> 10am - 9pm
                        </p>
                        <p>
                          <b className='day'>Saturday: </b> 9am - 1pm
                        </p>
                      </Col>
                      <Col>
                        <p>
                          <b className='day'>Chatswood:</b>
                        </p>
                        <p>
                          <b className='day'>Wednesday:</b> 2pm - 8pm
                        </p>
                        <p>
                          <b className='day'>Saturday:</b> 2pm - 6pm
                        </p>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck>
          </Container>
        )}
        <br />
        <Container>
          <div style={{ height: '60vh', width: '100%' }}>
            <GoogleMapReact
              distanceToMouse={() => { }}
              bootstrapURLKeys={{
                key: 'AIzaSyArS9npqBLae02VviuEo-qqcU_2xTH1j30'
              }}
              defaultCenter={{
                lat: -33.782062,
                lng: 151.057219
              }}
              defaultZoom={16}
              options={this.getMapOptions()}
            >
              <Marker
                lat={-33.782062}
                lng={151.057219}
                name='ODEO Pty Ltd'
                color='blue'
              />
            </GoogleMapReact>
          </div>
        </Container>

        <br />
      </div>
    )
  }
}

const mapStateToProps = state => {
  console.log(state)
  return {
    language: state.language.currentLanguage
  }
}

export default connect(mapStateToProps)(ContactPage)
