import React, { Component } from 'react'
import { Container, Row, Col, Button, Card, Accordion, Breadcrumb, Jumbotron, Image } from 'react-bootstrap'
import img7 from '../../img/treatment_img_5.jpg'
import { connect } from 'react-redux'

class AcutePage extends Component {
    state = {}
    render() {
        return (
            <div>
                <Jumbotron className="about-jumbotron" fluid>
                    <Container fluid>
                        <Row>
                            <Container fluid>
                                {this.props.language == "English" ? <h1 className="">Acute & Chronic Pain</h1> :
                                    <h1 className="">急性和慢性疼痛</h1>}
                            </Container>
                        </Row>

                    </Container>
                </Jumbotron>

                <Container>

                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                        <Breadcrumb.Item active>Acute & Chronic Pain</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row>
                        <Col>
                            <Image src={img7}
                                width="100%"
                                height="100%"
                                className="d-inline-block align-top"
                                alt="Acupuncture Treatment Image"
                            />
                        </Col>
                        <Col>
                            {this.props.language == "English" ? <p className="treatment-info-text">Are you suffering from muscloskeletal problems, migraine, period pain, chest pain, palpitation, sports injury, stiff muscle, nerve pain, pain caused by cancer treatment, etc. We use different modalities to give you pain relief. Acupuncture, Guasha, Cupping, herbal medicine might be used in combination to reduce your pain so you can get on with your daily life.</p>
                                : <p className="treatment-info-text">您是否患有肌肉骨骼疾病，偏头痛，经期疼痛，胸痛，心慌，运动损伤，僵硬的肌肉，神经痛，癌症治疗引起的疼痛等。我们使用不同的方法来减轻疼痛。 针灸，刮痧，拔罐，草药可以组合使用，以减轻疼痛，使您的日常生活更加轻松。</p>}
                        </Col>
                    </Row>
                </Container>
                <br />
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    console.log(state)
    return {
        language: state.language.currentLanguage
    }
}

export default connect(mapStateToProps)(AcutePage)