import React from 'react';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Homepage from './Components/Homepage'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import MyNavbar from './Components/Navbar'
import Footer from './Components/Footer'
import FaqPage from './Components/FaqPage'
import AboutPage from './Components/AboutPage'
import PricingPage from './Components/PricingPage'
import ContagePage from './Components/ContactPage'
import AnxietyPage from './Components/TreatmentPages/AnxietyPage'
import DigestivePage from './Components/TreatmentPages/DigestivePage'
import WeightPage from './Components/TreatmentPages/WeightPage'
import InfertilityPage from './Components/TreatmentPages/InfertilityPage'
import CosmeticPage from './Components/TreatmentPages/CosmeticPage'
import DermatologyPage from './Components/TreatmentPages/DermatologyPage'
import InsomniaPage from './Components/TreatmentPages/InsomniaPage'
import AcutePage from './Components/TreatmentPages/AcutePage'
import { Helmet } from "react-helmet";
function App() {
  return (
    <div className="App">

      <MyNavbar />
      <BrowserRouter>
        <Switch>

          <Route path='/contact' component={ContagePage} />
          <Route path='/pricing' component={PricingPage} />
          <Route path='/about' component={AboutPage} />
          <Route path='/anxiety' component={AnxietyPage} />
          <Route path='/healthy-weight' component={WeightPage} />
          <Route path='/digestive-disorder' component={DigestivePage} />
          <Route path='/womens-health' component={InfertilityPage} />
          <Route path='/cosmetic' component={CosmeticPage} />
          <Route path='/dermatology' component={DermatologyPage} />
          <Route path="/insomnia" component={InsomniaPage} />
          <Route path="/acute-pain" component={AcutePage} />
          <Route path='/faq' component={FaqPage} />
          <Route path='/' component={Homepage} />
        </Switch>

      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
