import React, { Component } from 'react'
import { Container, Row, Col, Button, Card, Accordion, Breadcrumb, Jumbotron, Image } from 'react-bootstrap'
import img8 from '../../img/treatment_img_12.jpg'
import { connect } from 'react-redux'

class DigestivePage extends Component {
    state = {}
    render() {
        return (
            <div>
                <Jumbotron className="about-jumbotron" fluid>
                    <Container fluid>
                        <Row>
                            <Container fluid>
                                {this.props.language == "English" ? <h1 className="">Digestive Disorders</h1> :
                                    <h1 className="">消化系统疾病</h1>}

                            </Container>
                        </Row>

                    </Container>
                </Jumbotron>

                <Container>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                        <Breadcrumb.Item active>Digestive Disorders</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row>
                        <Col>
                            <Image src={img8}
                                width="100%"
                                height="100%"
                                className="d-inline-block align-top"
                                alt="Acupuncture Treatment Image"

                            />
                        </Col>
                        <Col>
                            {this.props.language == "English" ? <p className="treatment-info-text">Digestive disorders include conditions such as indigestion, nausea, vomiting, acid Reflux, burping, bloating, constipation/loose stool and abdominal pain. Chinese herbs are indicated to help the digestive organs such as Stomach/Spleen, Liver/Gall Bladder and Small/Large Intestine to restore the movement of Qi, improve breakdown of food, uptake and transformation of nutrients and eliminate waste.  </p>
                                : <p className="treatment-info-text">消化系统疾病包括消化不良，恶心，呕吐，胃酸反流，打bur，腹胀，便秘/大便稀疏和腹痛等病症。 中草药可以帮助消化器官，例如胃/脾脏，肝/胆囊和小肠/大肠，恢复气的运动，改善食物分解，营养的吸收和转化并消除毒素。   </p>
                            }
                        </Col>
                    </Row>
                </Container>
                <br />
            </div>
        );
    }
}



const mapStateToProps = (state) => {
    console.log(state)
    return {
        language: state.language.currentLanguage
    }
}

export default connect(mapStateToProps)(DigestivePage)